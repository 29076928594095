export type OnboardingStepLocalStorageKey =
    | 'isUserProfileStepDone'
    | 'isCompanyProfileStepDone'
    | 'isForwardingEmailStepDone'
    | 'didUserImportAnyProperties'
    | 'isImportPropertiesStepDone'
    | 'didUserImportAnyLeads'
    | 'isImportLeadsStepDone';

type LocalStorageKey = OnboardingStepLocalStorageKey;

export class LocalStorageUtils {
    static set = (key: LocalStorageKey, value?: string) => {
        window.localStorage.setItem(key, value ?? '');
    };

    static get = (key: LocalStorageKey) => {
        return window.localStorage.getItem(key);
    };

    static remove = (key: LocalStorageKey) => {
        window.localStorage.removeItem(key);
    };

    static has = (key: LocalStorageKey) => {
        return window.localStorage.getItem(key) != null;
    };
}
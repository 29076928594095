import React, { createContext, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import Auth from 'src/services/api/auth/Auth';
import { type Notification, useNotifications } from 'src/services/api/notifications';
import { ThemeProvider } from 'src/theme';
import { FlexColumn } from 'src/components/flex';

type AppState = {
    isHelpSidebarOpened: boolean;
    setHelpSidebarOpened: Action<boolean>;

    isTawkChatOpened: boolean;
    setTawkChatOpened: Action<boolean>;

    notifications: Notification[];
};

// @ts-expect-error lazy init
export const AppStateContext = createContext<AppState>();

export const useAppState = () => {
    const context = React.useContext(AppStateContext);

    if (!context) {
        throw new Error('useAppState must be used within a AppStateProvider');
    }
    return context;
};

type Props = {
    children: React.ReactNode;
};

export default function AppStateProvider({ children }: Props) {
    const isLoggedIn = !!Auth.accessToken;

    const [isHelpSidebarOpened, setHelpSidebarOpened] = useState(false);
    const [isTawkChatOpened, setTawkChatOpened] = useState(false);

    const { data: notifications } = useNotifications(!isLoggedIn);

    const isLoading = isLoggedIn && !notifications;

    return (
        <AppStateContext.Provider
            value={{
                isHelpSidebarOpened,
                setHelpSidebarOpened,

                isTawkChatOpened,
                setTawkChatOpened,

                notifications: notifications || [],
            }}
        >
            {isLoading ? (
                <ThemeProvider>
                    <FlexColumn
                        minHeight="100vh"
                        bgcolor="var(--background-color-beige)"
                        rowGap={0}
                    >
                        <LinearProgress />
                    </FlexColumn>
                </ThemeProvider>
            ) : (
                children
            )}
        </AppStateContext.Provider>
    );
}

type Action<T> = React.Dispatch<React.SetStateAction<T>>;


import { useQuery } from 'react-query';

import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';

const PATH = 'api/landlord/notifications';

export enum ENotificationType {
    FailedToMatchListingToProperty = 'failed_to_match_listing_to_property',
}

export const isUnmatchedListingNotification = (n: Notification): n is UnmatchedListingNotification => {
    return n.type === ENotificationType.FailedToMatchListingToProperty;
};

type NotificationBase = {
    id: number;
    text: string;
    type: ENotificationType;
    is_read_by_user: boolean;
    is_informational: boolean;
    is_shared_with_team: boolean;
    inserted_at: string;
    modified_at: string;
};

export type UnmatchedListingNotification = NotificationBase & {
    payload: {
        name: string;
        ingestion_source: string;
        ingestion_method: string;
        leasing_team_id: number;
        num_leads: number;
    }
};

export type Notification = UnmatchedListingNotification;

type Response = {
    notifications: Notification[];
};

export const useNotifications = (disabled?: boolean) => {
    return useQuery(
        [queryKeys.notifications],
        async () => {
            const response = await apiClient(PATH).json<Response>();
            return response.notifications;
        }, {
            enabled: !disabled,
        }
    );
};

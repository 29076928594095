import ky from 'ky';

import { type IUser } from 'src/api/users-api';
import { type IStandardResponse, createBearerHeader } from 'src/common/api-utils';
import { Config } from 'src/config';
import type { ILeadLabel } from 'src/services/api';

export enum LeadStage {
    NOT_SET = 'not_set',
    NEW = 'new',
    CONTACTED = 'contacted',
    TOUR_SCHEDULED = 'tour_scheduled',
    TOUR_COMPLETED = 'tour_completed',
    INVITED_TO_APPLY = 'invited_to_apply',
    APPLICATION_COMPLETED = 'application_completed',
    APPLICATION_APPROVED = 'application_approved',
    NOT_QUALIFIED = 'not_qualified',
}

export const LEAD_STAGES = Object.values(LeadStage);
/**
 * The stages that are selectable by the user.
 * This is all stages except for `NOT_SET`.
 */
export const SELECTABLE_LEAD_STAGES = LEAD_STAGES.filter((o) => o !== LeadStage.NOT_SET);

/**
 * This mirrors the `RenterLead` model in models.py
 * Specifically the `to_json` method
 */
export interface ILead {
    uuid: string;
    /**
     * Reference to the user to which this lead belongs
     * Right now that's the current user
     */
    owner: number;
    /**
     * The leasing team that is associated with this lead
     * (may sometimes be null if not backfilled properly)
     */
    leasing_team_id: number | null;
    /**
     * Their full name
     * It may be null
     */
    name: string | null;
    email: string | null;
    phone_number: string | null;
    /**
     * When this lead was inserted into the database
     */
    inserted_at: Date;
    /**
     * When this lead was edited
     */
    modified_at: Date;
    /**
     * Where the landlord got the lead (Zumper, Yardi, etc.)
     */
    ingestion_source: string;
    property_id: number | null;
    /**
     * This is a pile of stuff we are mostly extracting from listing emails
     */
    smart_fields_data?: Record<string, string | Record<string, string>>;
    /**
     * How the lead came to be
     * e.g. csv-file, listing-site-email, onboarding-import, etc.
     */
    ingestion_method: string;
    /**
     * Whether this is a lead made specifically for testing by this user
     * Usually will match their own email and phone number
     */
    is_synthetic: boolean;
    /**
     * A landlord can archive a lead
     */
    is_archived?: boolean;
    /**
     * Whether this lead has an upcoming showing
     */
    has_upcoming_showing: boolean;
    /**
     * notes object for right sidebar
     */
    notes: ILeadNotes | null;

    stage: LeadStage;

    labels: ILeadLabel[];

    facebook_user_id: string | null;
}

export interface ILeadNotes {
    id: number;
    lead_uuid: string;
    leasing_team_id: number;
    contents: string;

    // dates are ISO formatted
    created_at: string;
    modified_at: string;
}

export interface IGetLeadDetailsResponse {
    lead: ILead;
}

export enum NewLeadField {
    Name = 'name',
    Email = 'email',
    PhoneNumber = 'phone_number',
    IngestionSource = 'ingestion_source',
    Property = 'property',
    IsSynthetic = 'is_synthetic',
    Labels = 'labels',
}

/**
 * Parameters required to create a new lead
 * Should match with CreateLeadSchema
 */
export interface INewLead {
    [NewLeadField.Name]: string;
    [NewLeadField.Email]: string | null;
    [NewLeadField.PhoneNumber]?: string | null;
    [NewLeadField.IngestionSource]: string;
    /**
     * The name of the property.
     * Omit if not associated with a property.
     */
    [NewLeadField.Property]?: string;
    [NewLeadField.IsSynthetic]?: boolean;
    [NewLeadField.Labels]?: ILeadLabel[];
}

export interface ICreateLeadResponse {
    uuid: string;
    lead: ILead;
}

/**
 * Uses ky
 * @throws {HTTPError} on failure
 */
export async function createLead(accessToken: string, newLead: INewLead): Promise<ICreateLeadResponse> {
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/leads/new';
    const headers = createBearerHeader(accessToken);
    const data = { ...newLead };
    return ky.post(url.toString(), {
        headers: headers,
        json: data,
    }).json() as Promise<ICreateLeadResponse>;
}

/**
 * Uses ky
 * @throws {HTTPError} on failure
 */
export async function createSyntheticLead(accessToken: string, user: IUser): Promise<ICreateLeadResponse> {
    console.debug('Adding synthetic lead...');
    const newLead = {
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
        ingestion_source: 'synthetic',
        is_synthetic: true,
    } as INewLead;
    return createLead(accessToken, newLead);
}

interface IUpdateNotesResponse {
    status: string;
    msg: string;
    notes: ILeadNotes;
}

/**
 * Uses ky
 * @throws {HTTPError} on failure
 */
export async function updateNotes(accessToken: string, leadUuid: string, notes: string): Promise<IUpdateNotesResponse> {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/leads/${leadUuid}/notes`;
    const headers = createBearerHeader(accessToken);
    const data = {
        notes: notes,
    };
    return ky.patch(url.toString(), { headers: headers, json: data }).json() as Promise<IUpdateNotesResponse>;
}

export async function deleteLead(accessToken: string, uuid: string) {
    if (!accessToken) {
        throw new Error('access token not set');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/leads/${uuid}`;
    const headers = createBearerHeader(accessToken);
    return ky.delete(url.toString(), {
        headers: headers,
    }).json() as Promise<IStandardResponse>;
}

export default {
    createLead,
    createSyntheticLead,
    delete: deleteLead,
    updateNotes,
};
import React from 'react';
import { Navigate } from 'react-router-dom';

import Auth from 'src/services/api/auth/Auth';

type Props = {
  children: React.ReactNode;
  redirectTo: string;
};

export default function ProtectedRoute({ redirectTo, children }: Props) {
    const accessToken = Auth.accessToken;

    if (!accessToken) {
        return <Navigate to={redirectTo} replace />;
    }

    return <>{children}</>;
}
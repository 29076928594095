import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import LandlordProtectedRoute from 'src/components/auth/LandlordProtectedRoute';

const LandlordProperties = lazy(() => import('src/pages/landlord/properties/LandlordProperties'));
const LandlordConversations = lazy(() => import('src/pages/landlord/conversations/LandlordConversations'));
const LeadsPage = lazy(() => import('src/pages/landlord/leads/all/LeadsPage'));
const LeadMessagesPage = lazy(() => import('src/pages/landlord/leads/messages/LeadMessagesPage'));
const LandlordAccount = lazy(() => import('src/pages/landlord/account/LandlordAccount'));
const PlaybooksPage = lazy(() => import('src/pages/landlord/playbooks/index/PlaybooksPage'));
const PlaybooksNewPage = lazy(() => import('src/pages/landlord/playbooks/new/PlaybooksNewPage'));
const PlaybooksEditPage = lazy(() => import('src/pages/landlord/playbooks/edit/PlaybooksEditPage'));
const ShowingsPage = lazy(() => import('src/pages/landlord/showings/ShowingsPage'));
const AnalyticsPage = lazy(() => import('src/pages/landlord/analytics/AnalyticsPage'));
const LandlordTeam = lazy(() => import('src/pages/landlord/team/LandlordTeam'));
const LandlordEmailsPage = lazy(() => import('src/pages/landlord/emails/LandlordEmailsPage'));
const UnmatchedListingsPage = lazy(() => import('src/pages/landlord/unmatched-listings/UnmatchedListingsPage'));
const LeadRoutingRulesPage = lazy(() => import('src/pages/landlord/lead-routing-rules/LeadRoutingRulesPage'));

/**
 * Routes for the landlord protected pages.
 * They have to be nested under the `/landlord/inner` route.
 */
export const landlordProtectedRoutes = [
    <Route key="emails" path="emails" element={<LandlordProtectedRoute><LandlordEmailsPage /></LandlordProtectedRoute>} />,
    <Route key="analytics" path="analytics" element={<LandlordProtectedRoute><AnalyticsPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks" path="playbooks" element={<LandlordProtectedRoute><PlaybooksPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks/new" path="playbooks/new" element={<LandlordProtectedRoute><PlaybooksNewPage /></LandlordProtectedRoute>} />,
    <Route key="playbooks/:id" path="playbooks/:id" element={<LandlordProtectedRoute><PlaybooksEditPage /></LandlordProtectedRoute>} />,
    <Route key="properties" path="properties" element={<LandlordProtectedRoute><LandlordProperties /></LandlordProtectedRoute>} />,
    <Route key="messages" path="messages" element={<LandlordProtectedRoute><LandlordConversations /></LandlordProtectedRoute>} />,
    <Route key="messages/from-lead" path="messages/from-lead" element={<LandlordProtectedRoute><LeadMessagesPage /></LandlordProtectedRoute>} />,
    <Route key="showings" path="showings" element={<LandlordProtectedRoute><ShowingsPage /></LandlordProtectedRoute>} />,
    <Route key="account" path="account" element={<LandlordProtectedRoute><LandlordAccount /></LandlordProtectedRoute>} />,
    <Route key="leads/all" path="leads/all" element={<LandlordProtectedRoute><LeadsPage /></LandlordProtectedRoute>} />,
    <Route key="account/team" path="account/team" element={<LandlordProtectedRoute><LandlordTeam /></LandlordProtectedRoute>} />,
    <Route key="unmatched-listings" path="unmatched-listings" element={<LandlordProtectedRoute><UnmatchedListingsPage /></LandlordProtectedRoute>} />,
    <Route key="lead-ingestion-routing-rules" path="lead-ingestion-routing-rules" element={<LandlordProtectedRoute><LeadRoutingRulesPage /></LandlordProtectedRoute>} />,
];
import React, { useReducer, createContext, useContext } from 'react';

import {
    type OnboardingStepLocalStorageKey,
    LocalStorageUtils
} from 'src/utils/local-storage';

type OnboardingStepsContextType = {
    isUserProfileStepDone: boolean;
    setUserProfileStepDone: (done: boolean) => void;

    isCompanyProfileStepDone: boolean;
    setCompanyProfileStepDone: (done: boolean) => void;

    isForwardingEmailStepDone: boolean;
    setForwardingEmailStepDone: (done: boolean) => void;

    didUserImportAnyProperties: boolean;
    setDidUserImportAnyProperties: (done: boolean) => void;
    isImportPropertiesStepDone: boolean;
    setImportPropertiesStepDone: (done: boolean) => void;

    didUserImportAnyLeads: boolean;
    setDidUserImportAnyLeads: (done: boolean) => void;
    isImportLeadsStepDone: boolean;
    setImportLeadsStepDone: (done: boolean) => void;

    resetOnboardingSteps: () => void;
};

// @ts-expect-error lazy init
export const OnboardingStepsContext = createContext<OnboardingStepsContextType>();

export const useOnboardingStepsContext = () => {
    const context = useContext(OnboardingStepsContext);

    if (!context) {
        throw new Error('useOnboardingStepsContext must be used within a LandlordOnboardingProvider');
    }

    return context;
};

type State = Record<OnboardingStepLocalStorageKey, boolean>;

const reducer = (state: State, action: Partial<State>) => {
    return {
        ...state,
        ...action,
    };
};

const initialState: State = {
    isUserProfileStepDone: LocalStorageUtils.has('isUserProfileStepDone'),
    isCompanyProfileStepDone: LocalStorageUtils.has('isCompanyProfileStepDone'),
    isForwardingEmailStepDone: LocalStorageUtils.has('isForwardingEmailStepDone'),
    didUserImportAnyProperties: LocalStorageUtils.has('didUserImportAnyProperties'),
    isImportPropertiesStepDone: LocalStorageUtils.has('isImportPropertiesStepDone'),
    didUserImportAnyLeads: LocalStorageUtils.has('didUserImportAnyLeads'),
    isImportLeadsStepDone: LocalStorageUtils.has('isImportLeadsStepDone'),
};

type Props = {
    children: React.ReactNode;
};

export default function OnboardingStepsProvider({ children }: Props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setState = (key: keyof State, done?: boolean) => {
        dispatch({ [key]: done });

        if (done) {
            LocalStorageUtils.set(key, String(done));
        } else {
            LocalStorageUtils.remove(key);
        }
    };

    const getSetState = (key: keyof State) => (done: boolean) => setState(key, done);

    const resetOnboardingSteps = () => {
        dispatch(initialState);

        LocalStorageUtils.remove('isUserProfileStepDone');
        LocalStorageUtils.remove('isCompanyProfileStepDone');
        LocalStorageUtils.remove('isForwardingEmailStepDone');
        LocalStorageUtils.remove('didUserImportAnyProperties');
        LocalStorageUtils.remove('isImportPropertiesStepDone');
        LocalStorageUtils.remove('didUserImportAnyLeads');
        LocalStorageUtils.remove('isImportLeadsStepDone');
    };

    return (
        <OnboardingStepsContext.Provider value={{
            isUserProfileStepDone: state.isUserProfileStepDone,
            setUserProfileStepDone: getSetState('isUserProfileStepDone'),

            isCompanyProfileStepDone: state.isCompanyProfileStepDone,
            setCompanyProfileStepDone: getSetState('isCompanyProfileStepDone'),

            isForwardingEmailStepDone: state.isForwardingEmailStepDone,
            setForwardingEmailStepDone: getSetState('isForwardingEmailStepDone'),

            didUserImportAnyProperties: state.didUserImportAnyProperties,
            setDidUserImportAnyProperties: getSetState('didUserImportAnyProperties'),
            isImportPropertiesStepDone: state.isImportPropertiesStepDone,
            setImportPropertiesStepDone: getSetState('isImportPropertiesStepDone'),

            didUserImportAnyLeads: state.didUserImportAnyLeads,
            setDidUserImportAnyLeads: getSetState('didUserImportAnyLeads'),
            isImportLeadsStepDone: state.isImportLeadsStepDone,
            setImportLeadsStepDone: getSetState('isImportLeadsStepDone'),

            resetOnboardingSteps,
        }}
        >
            {children}
        </OnboardingStepsContext.Provider>
    );
}

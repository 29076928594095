if (process.env.NODE_ENV !== 'test') {
    if (!import.meta.env['VITE_APP_API_SERVER']) {
        throw new Error('This variable must be set: VITE_APP_API_SERVER');
    }
}

/**
 * Miscellaneous configuration parameters here
 */
export const Config = {
    isLocal: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost',

    /**
     * Split backend and frontend
     */
    useSeparateBackendServer: true,

    /**
     * The backend server to which to submit information
     */
    backendServer: import.meta.env['VITE_APP_API_SERVER'],
};
export const queryKeys = {
    calendarIntegrations: 'calendarIntegrations',
    conversations: 'conversations',
    conversationsStats: 'conversationsStats',
    facebookIntegration: 'facebookIntegration',
    googleRedirectUrl: 'googleRedirectUrl',
    leads: 'leads',
    leadLabels: 'leadLabels',
    leadRoutingRules: 'leadRoutingRules',
    leadSources: 'leadSources',
    loggedInUser: 'loggedInUser',
    loggedInUserWithIngestEmailUsage: 'loggedInUserWithIngestEmailUsage',
    messages: 'messages',
    notifications: 'notifications',
    onboardingConfig: 'onboardingConfig',
    onboardingInvitation: 'onboardingInvitation',
    outlookRedirectUrl: 'outlookRedirectUrl',
    playbooks: 'playbooks',
    playbookTextTemplateVariables: 'playbookTextTemplateVariables',
    properties: 'properties',
    propertyLabels: 'propertyLabels',
    propertyApplicationLink: 'propertyApplicationLink',
    showingSchedulingLink: 'showingSchedulingLink',
    showings: 'showings',
    smartReplies: 'smartReplies',
    userSettings: 'userSettings',
    teamSettings: 'teamSettings',
};